import React, { Component } from "react";
import dynamic from "next/dynamic";
const Carousel = dynamic(() => import("react-spring-3d-carousel"), {
  ssr: false,
});
import { config } from "react-spring";

export default class Example extends Component {
  state = {
    offsetRadius: 2,
    showNavigation: true,
  };

  slides = [
    {
      content: <img src="https://picsum.photos/800/801/?random" alt="1" />,
    },
    {
      content: <img src="https://picsum.photos/800/802/?random" alt="2" />,
    },
    {
      content: <img src="https://picsum.photos/600/803/?random" alt="3" />,
    },
    {
      content: <img src="https://picsum.photos/800/500/?random" alt="4" />,
    },
    {
      content: <img src="https://picsum.photos/800/804/?random" alt="5" />,
    },
    {
      content: <img src="https://picsum.photos/500/800/?random" alt="6" />,
    },
    {
      content: <img src="https://picsum.photos/800/600/?random" alt="7" />,
    },
    {
      content: <img src="https://picsum.photos/805/800/?random" alt="8" />,
    },
  ].map((slide, index) => {
    return { ...slide, onClick: () => this.setState({ goToSlide: index }) };
  });

  render() {
    return (
      <div
        className="animation-wrapper"
        style={{ minWidth: "75vw", height: "500px", margin: "0 auto" }}
      >
        <Carousel
          slides={this.slides}
          offsetRadius={this.state.offsetRadius}
          showNavigation={this.state.showNavigation}
          goToSlideDelay={200}
          animationConfig={{ tension: 120, friction: 14 }}
        />
        <div
          className="move-left"
          style={{
            margin: "0 auto",
            marginTop: "2rem",
            width: "50%",
            display: "flex",
            justifyContent: "space-around",
          }}
        ></div>
      </div>
    );
  }
}
