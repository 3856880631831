import { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Image} from "@chakra-ui/react";
import {
  useDisclosure,
} from "@chakra-ui/react"

function CirclePerimeter() {
const graph = useRef(null);

  useEffect(() => {
    const ciclegraph = graph.current;
    const circleElements = ciclegraph.childNodes;

    let angle = 360 - 90;
    let divideAngleBasedOnEle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      let circle = circleElements[i];
      angle += divideAngleBasedOnEle;
      circle.style.transform = `rotate(${angle}deg) translate(${ciclegraph.clientWidth /
        2}px) rotate(-${angle}deg)`;
    }
  }, []);
  
  return (

    <Box>
        <Box className="center-rei-link-icon, circle">
          <Image className="center-rei-link-icon" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="rei-icon-text">
            REI LINK
          </p>
        </Box>
      <Box className="ciclegraph" ref={graph}>


        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 1
          </p>
        </Box>
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 2
          </p>
        </Box>        
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 3
          </p>
        </Box>
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 4
          </p>
        </Box>        
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 5
          </p>
        </Box>
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 6
          </p>
        </Box>        
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 7
          </p>
        </Box>
        <Box className="circle">
          <Image className= "circle" src= " https://images.pexels.com/photos/145971/pexels-photo-145971.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260 "/>
          <p className="icon-text">
            Lorem Ipsum 8
          </p>
        </Box>

      </Box>

    </Box>

//         <Flex flex={1} justifyContent="center" alignItems="center" >
//             {width ? 
//               <Flex 
                
//                   borderRadius={(Math.round((width) + (height)) )/ 2}
//                   width={(width) * 0.8}
//                   height={(height) * 0.8}
//                   borderWidth={5}
//                   borderColor='red'
//                   justifyContent='center'
//                   alignItems='center'
                
//               >
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg"  height={80} width={80} position='absolute' bottom={width * 0.7} />
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg" height={80} width={80} position='absolute' bottom={width * 0.6} right={20} />
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg" height={80} width={80} position='absolute' bottom={width * 0.6} left={20}  />
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg" height={80} width={80} position='absolute' top={width* 0.7}  />
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg" height={80} width={80} position='absolute' top={width * 0.6} left={20}  />
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg" height={80} width={80} position='absolute' bottom={width * 0.3} left={-20}  />
//                 < Image src= "https://image.shutterstock.com/image-photo/lion-king-isolated-on-black-600w-564253519.jpg" height={80} width={80} position='absolute' bottom={width * 0.3} left={-20}  />               
//               </Flex>
//             : null
//             }
//         </Flex>

  );
};

export default CirclePerimeter;

