import { useState } from "react";
import USAMap from "react-usa-map";
import { Box } from "@chakra-ui/react";
import MapModal from "src/components/alternativeMap/modal/MapModal";
import { useDisclosure } from "@chakra-ui/react";
const AltMap = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stateName, setStateName] = useState();
  const mapHandler = (event) => {
    setStateName(event.target.dataset.name);
    onOpen();
  };
  console.log(stateName)
  /* optional customization of filling per state and calling custom callbacks per state */
  const statesCustomConfig = () => {
    return {};
  };

  return (
    <Box h="100%" w="100%">
      {stateName != null ? (
        <MapModal
          stateName={stateName}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      ) : null}
      <USAMap customize={statesCustomConfig()} onClick={mapHandler} />
    </Box>
  );
};
export default AltMap;
