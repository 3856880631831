import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  Box,
  Text,
} from "@chakra-ui/react";
import { useState, useEffect, useRef } from "react";
import states from "src/lib/utils/cloudinary/object/statePath";
import useMap from "src/lib/utils/swr/hooks/useMap";
import * as d3 from "d3";
import useUserLocation from 'src/lib/utils/swr/hooks/useUserLocation'
import abbrToState from 'src/lib/utils/map/abbrToState'

function MapModal({ stateName, isOpen, onOpen, onClose }) {
  const ref = useRef(null);
  const [width, setWidth] = useState()
  let height = 800;
  const {location} = useUserLocation(abbrToState(stateName).toLowerCase());
  const { data } = useMap(stateName);
  const [stateShape, setStateShape] = useState();
  const [statePath, setStatePath] = useState();
  
  useEffect(() => {
    
    states.states.map((state) => {
      if (state.state == stateName) {
        setStatePath(state);
        setWidth(state.w)
      }
    });
  });
  useEffect(() => {
    if (data && location) {
      
      
      const geojson = JSON.parse(data[0].geo_json);
      setStateShape(geojson);
    }
  }, [data, location, statePath]);
  useEffect(() => {
    if (stateShape?.features && ref) {
        
      const projection = d3
        .geoMercator()
        .fitSize([width / 2, height / 2], stateShape.features[0])
        .precision(200);
      const geoGenerator = d3.geoPath().projection(projection);
      const svg = d3
        .select(ref.current, "Box.map")
        .selectAll("path")
        .data(stateShape.features);
      svg
        .enter()
        .append("path")
        .attr("class", "drawn-path")
        .style("align-self", "center")
        .style("justify-self", "center")
        .style("margin", "auto")
        .style("stroke", "darkgrey")
        .style("stroke-width", "3px")
        .style("fill", "#DCDCDC")
        .attr("d", geoGenerator);
        
     const svgMarkers = d3
        .select(ref.current, "Box.circles")
        .selectAll("circles")
        .data(stateShape.features);
      const circleProjection = d3.geoMercator();
      location.map(spot => {
        const plusOrMinus = Math.floor(Math.random() * 10);
        const varience1 =  (plusOrMinus) > 4 ? Math.floor(Math.random() * 5) : Math.floor(Math.random() * -5);
        const varience2 =  (plusOrMinus) > 4 ? Math.floor(Math.random() * 5) : Math.floor(Math.random() * -5);
      const aa = [spot.long + varience1, location[0].lat + varience2];
      const bb = [spot.long + 0.2 + varience1, spot.lat + 0.2 + varience2]
        svgMarkers
        .data([aa,bb]).enter()
        .append("circle")
        .attr("fill", "#00FFFF")
        .attr("stroke", "rgba(0, 0, 0, 0.5)")
        .attr("stroke-width", 1)
        .attr("r", 8)
        .attr("cx", function (d) { return circleProjection(d)[0]; })
        .attr("cy", function (d) { return circleProjection(d)[1]; })
      })

    }
  }, [stateShape, ref]);
  return (
    <Box mt="5vh">
      <Modal motionPreset="slideInRight" isOpen={isOpen} onClose={() => {
        onClose()
        setStatePath(null)
        setStateShape(null)
        setWidth(null)
        }}>
        <ModalOverlay backgroundColor="rgba(50,50,50,0.8)" />
        <ModalContent
          boxShadow="none"
          marginTop="20vh"
          background="none"
          minWidth="90vw"
        >
          <ModalBody minWidth="90vw">
            <Flex direction="column" alignItems="center" justifyContent="center">
              <Flex justifyContent="center" width="50%">
                <Box
                  display="flex"
                  height="auto"
                  width="100vw"
                  alignItems="center"
                  justifyContent="center"
                >
                  <svg ref={ref} width={width * 0.5} height={height * 0.5}>
                    <Box
                      className="map"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    ></Box>
                    <Box
                      className="circles"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    ></Box>
                  </svg>
                </Box>
              </Flex>
              <Flex background="white" justifyContent="center" width="80vw">
                info
              </Flex>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              background="white"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default MapModal;
