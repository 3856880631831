import { useRef, useState, useEffect } from 'react';
import { Box, Flex, Text} from "@chakra-ui/react";
import {
  useDisclosure,
} from "@chakra-ui/react"

const Clock = () => {
  const [countdownDays, setCountdownDays] = useState('00');
  const [countdownHours, setCountdownHours] = useState('00');
  const [countdownMinutes, setCountdownMinutes] = useState('00');
  const [countdownSeconds, setCountdownSeconds] = useState('00');
  
  let interval = useRef();

  const startCountdownTimer = () => {
    const dateToCountdownTo = new Date( "June 11, 2021 00:00:00" ).getTime();

    interval = setInterval( () => {
      const present = new Date().getTime();
      const difference = dateToCountdownTo - present;

      const days = Math.floor(difference / ( 1000 * 60 * 60 * 24 ));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
      const minutes = Math.floor((difference % (1000 * 60 * 60) / (1000 * 60)));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      // stops countdown when it zeros out

      if( difference < 0 ){
        clearInterval(interval.current);
      } else {
        setCountdownDays(days);
        setCountdownHours(hours)
        setCountdownMinutes(minutes);
        setCountdownSeconds(seconds);

      }

    }, 1000) 
  };

  useEffect(() => {
    startCountdownTimer();
    return () => {
      clearInterval(interval.current);
    }
  });



  return (
      <Flex
        flexDirection="column"
        p={10}
        alignItems="center"
        justifyContent="space-around"
      >
        <Text mb={3}>
          Preregister before launch and get 6 months free full access on us!
        </Text>

        {/* Set up Count clock below Day, Hour, Minutes, Seconds */}
        <Flex>
          <Flex flexDirection="column" mr={8}>
            <Text fontWeight="bold" textAlign="center">
              {countdownDays}
            </Text>
            <Text textAlign="center">
              { countdownDays === 1 ? 'Day' : 'Days' }
            </Text>

          </Flex>
          <Flex flexDirection="column" mr={8}>
            <Text fontWeight="bold" textAlign="center" letterSpacing="wide">
              {countdownHours}
            </Text>
            <Text textAlign="center">
              { countdownHours === 1 ? 'Hour' : "Hours" }
            </Text>
          </Flex>
          <Flex flexDirection="column" mr={8}>
            <Text fontWeight="bold" textAlign="center" letterSpacing="wide">
              {countdownMinutes}
            </Text>
            <Text textAlign="center">
              { countdownMinutes === 1 ? "Minute" : "Minutes" }
            </Text>
          </Flex>
          <Flex flexDirection="column" mr={8}>
            <Text fontWeight="bold" textAlign="center" letterSpacing="wide">
              {countdownSeconds}
            </Text>
            <Text textAlign="center">
              { countdownSeconds === 1 ? "Second" : "Seconds" }
            </Text>
          </Flex>
        </Flex>
      </Flex>

  )
};
export default Clock;
