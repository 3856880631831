export default {
  states: [
    { path: "utah-01_zeuits", state: "UT", w: 647 },
    { path: "south-dakota-01_wpn6c4", state: "SD", w: 1336 },
    { path: "texas-01_jkrc8z", state: "TX", w: 862 },
    { path: "tennessee-01_t906y7", state: "TN", w: 1927 },
    { path: "rhode-island-01_sueyrs", state: "RH", w: 651 },
    { path: "south-carolina-01_cacqbg", state: "SCs", w: 1059 },
    { path: "pennsylvania-01_drgt6p", state: "PA", w: 1102 },
    { path: "ohio-01_mzmrx3", state: "OH", w: 706 },
    { path: "oregon-01_ftlqbs", state: "OR", w: 962 },
    { path: "oklahoma-01_xwmsa3", state: "OK", w: 1666 },
    { path: "new-mexico-01_c0okpl", state: "NM", w: 768 },
    { path: "north-carolina-01_h7ctt9", state: "NC", w: 1669 },
    { path: "new-jersey-01_d6i9fi", state: "NJ", w: 360 },
    { path: "new-york-01_i4f4bs", state: "NY", w: 1065 },
    { path: "nebraska-01_qjuu1o", state: "NE", w: 1724 },
    { path: "new-hampshire-01_wdskhr", state: "NH", w: 390 },
    { path: "nevada-01_cmnl9v", state: "NV", w: 528 },
    { path: "montana-01_lvq4oh", state: "MT", w: 1280 },
    { path: "missouri-01_yfuuq9", state: "MO", w: 983 },
    { path: "mississippi-01_zolmnt", state: "MS", w: 474 },
    { path: "minnesota-01_vgnr6j", state: "MN", w: 693 },
    { path: "michigan-01_lo8eee", state: "MI", w: 950 },
    { path: "louisiana-01_inzhc9", state: "LA", w: 971 },
    { path: "massachusetts-01_oxswtw", state: "MA", w: 1449 },
    { path: "maryland-01_psov1y", state: "MD", w: 1644 },
    { path: "maine-01_mbbo6x", state: "ME", w: 491 },
    { path: "kentucky-01_gunzla", state: "KY", w: 1445 },
    { path: "kansas-01_magv8q", state: "KA", w: 1539 },
    { path: "indiana-01_tmasup", state: "IN", w: 480 },
    { path: "iowa-01_nd6vea", state: "IA", w: 1232 },
    { path: "illinois-01_dfwdxs", state: "IL", w: 474 },
    { path: "hawaii-01_afpb9m", state: "HI", w: 1117 },
    { path: "idaho-01_p1rofz", state: "ID", w: 503 },
    { path: "georgia-01_nhvmyi", state: "GA", w: 788 },
    { path: "florida-01_tfsjbq", state: "FL", w: 1084 },
    { path: "delaware-01_loft0m", state: "DE", w: 536 },
    { path: "dc-01_fkcfzx", state: "DC", w: 874 },
    { path: "colorado-01_qmgctf", state: "CO", w: 1053 },
    { path: "connecticut-01_brxsk7", state: "CT", w: 780 },
    { path: "california-01_bt70ir", state: "CA", w: 491 },
    { path: "alaska-01_sbxrlo", state: "AK", w: 1264 },
    { path: "arkansas-01_mi4yts", state: "AR", w: 851 },
    { path: "arizona-01_t6dsln", state: "AZ", w: 696 },
    { path: "alabama-01_mopatc", state: "AL", w: 528 },
    { path: "wisconsin-01_wxv3bp", state: "WI", w: 1336 },
    { path: "virginia-01_ilqyt7", state: "VA", w: 1299 },
    { path: "west-virginia-01_thvupr", state: "WV", w: 780 },
    { path: "vermont-01_g1ksvb", state: "VT", w: 417 },
    { path: "wyoming-01_kpvtfv", state: "WY", w: 989 },
  ],
};
